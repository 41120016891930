var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "vld-parent",
    on: {
      "click": _vm.startTimer
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.isLoading,
      "can-cancel": false,
      "is-full-page": true,
      "transition": "bounce",
      "blur": "10px",
      "height": 80,
      "width": 150,
      "color": "#161c2d",
      "loader": "dots"
    },
    on: {
      "update:active": function ($event) {
        _vm.isLoading = $event;
      }
    }
  }), _c('router-view', {
    key: _vm.$route.path
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }