<template >
  <div>
    <div class="vld-parent" @click="startTimer">
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" transition="bounce" blur="10px" :height="80" :width="150" color="#161c2d" loader="dots">
        <!-- <label><input type="checkbox" />Full page?</label> -->
        <!-- <button @click.prevent="doAjax">fetch Data</button> -->
      </loading>
      <router-view :key="$route.path" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import { PREFERENCE } from "@/services/store/trust.module";
import { LOGOUT } from "@/services/store/auth.module";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: { Loading },
  mounted() {
    clearTimeout(this.timeOut);
    if (!["login", "sign-up", "wasiat-verification.auth", "wasiat-verification.details", "wasiat-verification.eky", "wasiat-verification.pdpa"].includes(this.$route.name)) {
      this.countDownTimer();
    }
    if (this.isAuthenticated) {
      if (this.organizationId != -1) {
        this.$store.dispatch(PREFERENCE, this.organizationId);
      }
    }

    if (["development"].includes(process.env.VUE_APP_ENVI)) {
      let s = document.createElement("script");
      s.src = "https://api.buglog.io/website/GNERMNLW4J/code";
      s.async = 1;
      document.head.appendChild(s);
    }
  },
  data() {
    return {
      // isLoading: true,
      timeOut: null,
      startTime: false,
      timeOutSecond: null,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "isLoading", "userAccess"]),
    organizationId: function () {
      if (this.userAccess != null) {
        return this.userAccess.organization_id;
      } else {
        return -1;
      }
    },
  },

  methods: {
    startTimer() {
      if (["login", "sign-up", "wasiat-verification.auth", "wasiat-verification.details", "wasiat-verification.eky", "wasiat-verification.pdpa"].includes(this.$route.name)) {
        this.startTime = false;
      }
      if (this.startTime) {
        console.log("activated");
        clearTimeout(this.timeOut);
        this.countDownTimer();
      }
    },
    countDownTimer() {
      clearTimeout(this.timeOut);

      this.timeOut = setTimeout(() => {
        // this.$store.dispatch(LOGOUT).then(() => {
        //   // if (this.$route.name != "Home") {
        //   this.$router.push({ name: "login" });
        //   // }
        // });

        this.startTime = true;

        var d = new Date();
        var v = new Date();
        v.setMinutes(d.getMinutes() + 1);

        this.$swal
          .fire({
            icon: "warning",
            html: "Anda telah membiarkan sistem ini lebih daripada 20 minit. Anda akan di log keluar selepas 1 minit jika tiada pilihan diambil.",
            showCancelButton: true,
            focusConfirm: false,
            cancelButtonText: "Tutup",
            confirmButtonText: "Log Keluar",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$store.dispatch(LOGOUT).then(() => {
                this.$router.push({ name: "login" });
              });
            } else {
              clearTimeout(this.timeOutSecond);
              this.countDownTimer();
            }
          });

        this.timeOutSecond = setTimeout(() => {
          this.$swal.close();
          this.$store.dispatch(LOGOUT).then(() => {
            this.$router.push({ name: "login" });
          });
        }, 50000);
        console.log("old timer");
      }, 1800000); //30 mins
      // 1800000 : 30 mins
      // 1200000 : 20 mins
      // 60000 : 1min
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
    this.startTime = false;
  },
  unmounted() {
    console.log("unmounted");
    clearTimeout(this.timeOut);
    this.startTime = false;
  },
};
</script>